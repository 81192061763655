import {
  IAdminStats,
  IBulkOperation,
  IChartDataResponse,
  IFacility,
  IFacilityInventory,
  IFacilityInventoryResponse,
  IPaymentHistoryResponse,
  IReservationAdminResponse,
  IReservationSearchResponse,
  IViewFacilityAirportResponse,
  IViewRateLine,
  IViewRateLineCollection,
  IViewRateLineCollectionDataResponse,
  IViewRateLineCollectionResponse,
  IViewRateTable,
  IViewRateTableDataResponse,
  IViewRateTablesResponse,
  IViewRevenueResponse,
  ViewFacilityPartnerUpdate,
} from '@/types';
import { fetchTemplate } from '@/utils/isServer';
import { mapFormEncoded } from '@/utils/tools';
import dayjs, { Dayjs } from 'dayjs';

export function procTime(data) {
  if (data) {
    return '1970-01-01T' + data;
  }
  return null;
}

class AdminService {
  public async searchHistoryChart(
    facility_id: number,
    ntype: number,
    dt_checkin: Dayjs,
    dt_checkout: Dayjs
  ): Promise<IChartDataResponse> {
    const params = {
      facility_id,
      group: ntype,
      dt_checkin: dt_checkin.format('YYYY-MM-DD'),
      dt_checkout: dt_checkout.format('YYYY-MM-DD'),
    };
    const response = await fetch(`/coreapi/admin/LoadDetailedChart`, {
      ...fetchTemplate,
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        ...fetchTemplate.headers,
        'Content-Type': 'application/json',
      },
    });

    const data: IChartDataResponse = await response.json();
    return data;
  }
  public async loadChartData(): Promise<IChartDataResponse> {
    const response = await fetch(`/coreapi/admin/LoadChartData`, {
      ...fetchTemplate,
      method: 'GET',
    });

    const data: IChartDataResponse = await response.json();
    return data;
  }

  public async loadPaymentHistory(
    facilityId: number
  ): Promise<IPaymentHistoryResponse> {
    const response = await fetch(`/coreapi/admin/PayoutHist/${facilityId}`, {
      ...fetchTemplate,
      method: 'GET',
    });

    const data: IPaymentHistoryResponse = await response.json();
    return data;
  }

  public async searchReservation(
    facilityId: number,
    firstName: string,
    lastName: string,
    code: string,
    checkin: string,
    checkout: string,
    phone: string
  ): Promise<IReservationSearchResponse> {
    const params = {
      facility_id: facilityId,
      reservation_first_name: firstName,
      reservation_last_name: lastName,
      reservation_code: code,
      reservation_dt_checkin: checkin,
      reservation_dt_checkout: checkout,
      reservation_phone: phone,
      member_id: 0,
    };
    const response = await fetch(`/coreapi/reservation/search`, {
      ...fetchTemplate,
      method: 'POST',
      body: mapFormEncoded(params),
      headers: {
        ...fetchTemplate.headers,
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    const data: IReservationSearchResponse = await response.json();
    data.result = data?.result?.map((item) => {
      item.dayjs_reservation_dt_checkin = dayjs(item.reservation_dt_checkin);
      item.dayjs_reservation_dt_checkout = dayjs(item.reservation_dt_checkout);
      item.dayjs_reservation_dt_inc = dayjs(item.reservation_dt_inc);
      item.dayjs_reservation_dt_cancelled = dayjs(
        item.reservation_dt_cancelled
      );
      return item;
    });
    return data;
  }

  public async loadViewFacilityAirport(
    showInactive: string
  ): Promise<IViewFacilityAirportResponse> {
    const response = await fetch(
      `/coreapi/admin/LoadViewFacilityAirport?showInactive=${showInactive}`,
      {
        ...fetchTemplate,
        method: 'GET',
      }
    );

    const data: IViewFacilityAirportResponse = await response.json();
    return data;
  }

  public async loadFacilityData(
    facilityId: number
  ): Promise<ViewFacilityPartnerUpdate> {
    const response = await fetch(
      `/coreapi/admin/LoadFacilityData/${facilityId}`,
      {
        ...fetchTemplate,
        method: 'GET',
      }
    );

    const data: ViewFacilityPartnerUpdate = await response.json();
    return data;
  }

  public async saveFacilityData(
    facilityData: ViewFacilityPartnerUpdate
  ): Promise<ViewFacilityPartnerUpdate> {
    const response = await fetch(`/coreapi/admin/SaveFacilityData`, {
      ...fetchTemplate,
      method: 'POST',
      body: JSON.stringify(facilityData),
      headers: {
        ...fetchTemplate.headers,
        'Content-Type': 'application/json',
      },
    });

    const data: ViewFacilityPartnerUpdate = await response.json();
    return data;
  }

  public async loadFacilityInventory(
    facilityId: number,
    extended: boolean
  ): Promise<IFacilityInventoryResponse> {
    const ext = extended ? '?extended=true' : '';

    const response = await fetch(
      `/coreapi/FacilityDate/Facility/${facilityId}${ext}`,
      {
        ...fetchTemplate,
        method: 'GET',
      }
    );

    const data: IFacilityInventoryResponse = await response.json();

    return {
      ...data,
      result: data.result.map((x) => {
        return this.enhanceFacilityInventory(x);
      }),
    };
  }

  private enhanceFacilityInventory(x: IFacilityInventory): IFacilityInventory {
    if (!x) return x;
    return {
      ...x,
      facility_start_date_dayjs: dayjs(x.facility_start_date),
      isSoldout: x.current_spots <= 0,
      isBlockedOut: x.facility_spots === 0,
      tier_data: JSON.parse(x.facility_tier_price),
    };
  }

  public async loadOneFacilityInventory(
    id: number
  ): Promise<IFacilityInventory> {
    const response = await fetch(`/coreapi/FacilityDate/${id}`, {
      ...fetchTemplate,
      method: 'GET',
    });

    const data: IFacilityInventory = await response.json();

    return this.enhanceFacilityInventory(data);
  }

  public async UpdateOneFacilityInventory(
    editData: IFacilityInventory
  ): Promise<IFacilityInventory> {
    var params: IFacilityInventory = {
      ...editData,
      facility_tier_price: editData.tier_data
        ? JSON.stringify(editData.tier_data)
        : null,
      tier_data: null,
      facility_start_date_dayjs: null,
    };

    const response = await fetch(`/coreapi/FacilityDate`, {
      ...fetchTemplate,
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        ...fetchTemplate.headers,
        'Content-Type': 'application/json',
      },
    });

    const data: IFacilityInventory = await response.json();

    return this.enhanceFacilityInventory(data);
  }

  public async BulkAction(bulkOperation: IBulkOperation): Promise<any> {
    const response = await fetch(`/coreapi/FacilityDateBatch`, {
      ...fetchTemplate,
      method: 'POST',
      body: JSON.stringify(bulkOperation),
      headers: {
        ...fetchTemplate.headers,
        'Content-Type': 'application/json',
      },
    });

    const data: any = await response.json();
    return data;
  }

  public async loadAdminReservationData(
    reservationId: number
  ): Promise<IReservationAdminResponse> {
    const response = await fetch(
      `/coreapi/admin/ReservationData/${reservationId}`,
      {
        ...fetchTemplate,
        method: 'GET',
      }
    );

    const data: IReservationAdminResponse = await response.json();

    return data;
  }

  public async reportRevenue(
    facilityId: number,
    group: number,
    type: number,
    checkin: Dayjs,
    checkout: Dayjs,
    typeReport: number
  ): Promise<IViewRevenueResponse> {
    const params = {
      facility_id: facilityId,
      group: group,
      inorout: type,
      dt_checkin: checkin.format('MM/DD/YYYY'),
      dt_checkout: checkout.format('MM/DD/YYYY'),
      type_report: typeReport,
    };
    const response = await fetch(`/api/reservation/revenue`, {
      ...fetchTemplate,
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        ...fetchTemplate.headers,
        'Content-Type': 'application/json',
      },
    });

    const data: IViewRevenueResponse = await response.json();

    return {
      ...data,
      result: data.result.map((x) => {
        return {
          ...x,
          reservation_dt_inc_dayjs: dayjs(x.reservation_dt_inc),
          reservation_dt_cancelled_dayjs: dayjs(x.reservation_dt_cancelled),
          reservation_dt_checkin_dayjs: dayjs(x.reservation_dt_checkin),
          reservation_dt_checkout_dayjs: dayjs(x.reservation_dt_checkout),
        };
      }),
    };
  }

  public async loadDailyDetails(
    facility_id: number
  ): Promise<IViewRevenueResponse> {
    const response = await fetch(`/coreapi/admin/DailyDetails/` + facility_id, {
      ...fetchTemplate,
      method: 'GET',
      headers: {
        ...fetchTemplate.headers,
        'Content-Type': 'application/json',
      },
    });

    const data: IViewRevenueResponse = await response.json();
    return data;
  }

  public async adminStats(): Promise<IAdminStats> {
    const response = await fetch(`/coreapi/admin/AdminStats`, {
      ...fetchTemplate,
      method: 'GET',
      headers: {
        ...fetchTemplate.headers,
        'Content-Type': 'application/json',
      },
    });

    const data: IAdminStats = await response.json();
    return data;
  }

  public async listRateTables(
    facilityId: number
  ): Promise<IViewRateTablesResponse> {
    const response = await fetch(`/coreapi/adminrt/${facilityId}`, {
      ...fetchTemplate,
      method: 'GET',
    });

    const data: IViewRateTablesResponse = await response.json();
    return data;
  }
  public async createRateTable(
    facilityId: number,
    name: string
  ): Promise<IViewRateTableDataResponse> {
    const params = { ratetable_name: name };
    const response = await fetch(`/coreapi/adminrt/${facilityId}`, {
      ...fetchTemplate,
      body: JSON.stringify(params),
      method: 'POST',
      headers: {
        ...fetchTemplate.headers,
        'Content-Type': 'application/json',
      },
    });

    const data: IViewRateTableDataResponse = await response.json();
    return data;
  }
  public async deleteRateTable(
    facilityId: number,
    rateTableId: number
  ): Promise<any> {
    const response = await fetch(
      `/coreapi/adminrt/${facilityId}/${rateTableId}`,
      {
        ...fetchTemplate,
        method: 'DELETE',
      }
    );

    const data: IViewRateTablesResponse = await response.json();
    return data;
  }
  public async loadRateTableData(
    facilityId: number,
    rateTableId: number
  ): Promise<IViewRateTableDataResponse> {
    const response = await fetch(
      `/coreapi/adminrt/${facilityId}/${rateTableId}`,
      {
        ...fetchTemplate,
        method: 'GET',
      }
    );

    const data: IViewRateTableDataResponse = await response.json();
    return data;
  }
  public async saveRateTable({
    facility_id,
    ratetable_id,
    ratetable_name,
    ratetable_active,
    ratetable_default,
  }): Promise<IViewRateTableDataResponse> {
    const params = {
      ratetable_name,
      ratetable_active,
      ratetable_default,
    };
    const response = await fetch(
      `/coreapi/adminrt/${facility_id}/${ratetable_id}`,
      {
        ...fetchTemplate,
        method: 'PUT',
        body: JSON.stringify(params),
        headers: {
          ...fetchTemplate.headers,
          'Content-Type': 'application/json',
        },
      }
    );

    const data: IViewRateTableDataResponse = await response.json();
    return data;
  }
  public async createRateTableSlot(
    facilityId: number,
    rateTableId: number,
    name: string
  ): Promise<IViewRateLineCollectionDataResponse> {
    const params = { rlc_name: name };
    const response = await fetch(
      `/coreapi/adminrt/${facilityId}/${rateTableId}/slots`,
      {
        ...fetchTemplate,
        body: JSON.stringify(params),
        method: 'POST',
        headers: {
          ...fetchTemplate.headers,
          'Content-Type': 'application/json',
        },
      }
    );

    const data: IViewRateLineCollectionDataResponse = await response.json();
    return data;
  }

  public async loadSlots(
    facilityId: number,
    rateTableId: number
  ): Promise<IViewRateLineCollectionResponse> {
    const response = await fetch(
      `/coreapi/adminrt/${facilityId}/${rateTableId}/slots`,
      {
        ...fetchTemplate,
        method: 'GET',
      }
    );

    const data: IViewRateLineCollectionResponse = await response.json();
    return data;
  }
  public async loadSlot(
    facilityId: number,
    rateTableId: number,
    rlcId: number
  ): Promise<IViewRateLineCollectionDataResponse> {
    const response = await fetch(
      `/coreapi/adminrt/${facilityId}/${rateTableId}/slots/${rlcId}`,
      {
        ...fetchTemplate,
        method: 'GET',
      }
    );

    const data: IViewRateLineCollectionDataResponse = await response.json();
    return data;
  }

  public async deleteSlot(
    facilityId: number,
    rateTableId: number,
    rlcId: number
  ): Promise<IViewRateLineCollectionResponse> {
    const response = await fetch(
      `/coreapi/adminrt/${facilityId}/${rateTableId}/slots/${rlcId}`,
      {
        ...fetchTemplate,
        method: 'DELETE',
      }
    );

    const data: IViewRateLineCollectionResponse = await response.json();
    return data;
  }
  public async saveSlot(
    facilityId: number,
    rateTableId: number,
    rlcId: number,
    rlc: IViewRateLineCollection,
    gridData: IViewRateLine[]
  ): Promise<IViewRateLineCollectionDataResponse> {
    const response = await fetch(
      `/coreapi/adminrt/${facilityId}/${rateTableId}/slots/${rlcId}`,
      {
        ...fetchTemplate,
        method: 'PUT',
        body: JSON.stringify({ ...rlc, RateLines: gridData }),
        headers: {
          ...fetchTemplate.headers,
          'Content-Type': 'application/json',
        },
      }
    );

    const data: IViewRateLineCollectionDataResponse = await response.json();
    return data;
  }
  public async testRateTable({
    facilityId,
    ratetableId,
    start_date,
    start_time,
    end_date,
    end_time,
  }: {
    facilityId: number;
    ratetableId: number;
    start_date: string;
    start_time: string;
    end_date: string;
    end_time: string;
  }): Promise<string> {
    const response = await fetch(
      `/coreapi/adminrt/${facilityId}/${ratetableId}/test`,
      {
        ...fetchTemplate,
        method: 'PUT',
        body: JSON.stringify({
          start_date:
            dayjs(start_date).format('YYYY-MM-DD') +
            'T' +
            dayjs(start_time).format('HH:mm'),
          end_date:
            dayjs(end_date).format('YYYY-MM-DD') +
            'T' +
            dayjs(end_time).format('HH:mm'),
        }),
        headers: {
          ...fetchTemplate.headers,
          'Content-Type': 'application/json',
        },
      }
    );

    const data: { amount: number; log: string[] } = await response.json();
    return JSON.stringify(data, null, 4);
  }
}

export default new AdminService();

