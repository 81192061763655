import { CDN2 } from '@/helpers/api';
import usePrefix from '@/hooks/usePrefix';
import { useMobxStores } from '@/stores';
// import NavBar from '@components/NavBar';
import { setLastPageVisited } from '@helpers/history';
import Login from '@pages/myapp/login';
import { isServer } from '@utils/isServer';
import { Layout } from 'antd';
import { observer } from 'mobx-react';
import dynamic from 'next/dynamic';
import NextHead from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

const { Content } = Layout;

const NavBar = dynamic(() => import('@/components2/NavBar'), { ssr: false });
const AntFooter = dynamic(() => import('@/components2/Footer'), { ssr: false });
const AntFooterNew = dynamic(() => import('@/components2/Footer'), {
  ssr: false,
});

export function reportWebVitals(metric: { label: string }) {
  if (metric.label === 'custom') {
    console.log(metric);
  }
}

const PrivateLayout = ({ children, airports, newFooter = false }) => {
  const { authStore } = useMobxStores(),
    user = authStore.user;
  const router = useRouter();
  const prefix = usePrefix();
  useEffect(() => {
    if (!user) router.push(prefix + '/login');
    setLastPageVisited();
  }, [router, user, prefix]);

  if (!user) {
    return (
      <Layout>
        <NavBar airports={airports} />
        <Login />
        <AntFooterNew />
      </Layout>
    );
  }

  return (
    <Layout>
      <NextHead>
        <meta name="msvalidate.01" content="70D794CFD1BC46E43E6BC2236189BAD2" />
        <meta
          property="og:url"
          content={!isServer ? window.location.toString() : undefined}
          key="url"
        />
        <meta property="og:type" content="website" key="og:type" />
        <meta property="og:title" content="On Air Parking" key="og:title" />
        <meta
          property="og:description"
          content="Affordable Quality Parking"
          key="og:description"
        />
        <meta
          property="og:image"
          content={CDN2() + '/staticmyapp/oglogo-white.png'}
          key="og:image"
        />
        <link rel="icon" href="/favicon.png" />
      </NextHead>
      <NavBar airports={airports} />
      <Content className="site-layout">{children}</Content>
      <AntFooterNew />
    </Layout>
  );
};

export default observer(PrivateLayout);
